import React from "react";
import "./style.css";
import { Col, Container, Row } from "react-bootstrap";

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row className="footer-row">
          <Col md={3} sm={5} className="box">
            <div className="logo">
              <ion-icon name="bag"></ion-icon>
              <h1>Güney Yıldızı</h1>
            </div>
            <p>Kalitenin Bir Numaralı Adresi...</p>
          </Col>
          <Col md={3} sm={5} className="box">
            <h2>İletişim</h2>
            <ul>
              <li>Dağlıoğlu mah 14200 sok No 2 A-B Seyhan/Adana </li>
              <li>Telefon: 0542 522 42 03</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
